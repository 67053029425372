<template>
  <div id="browser">
    <div class="content" style="background-color: white;">
      <div class="mac" v-if="$route.query.id == 4">
        <!-- <img class="load_jpg" :src="load_jpg" alt="" /> -->
        <div class="qrcodeDiv">
          <vue-qr
              :margin="0"
              text="https://xiaoguoai.cn/admin"
              :logoSrc="url"
              colorDark="#000000"
              colorLight="#fff"
              :correctLevel="3"
              :logoScale="0.2"
              :size="200"
          >
          </vue-qr>
        </div>
        <br>
        <span style="color: green;">后台适配安卓版app</span>
        <br>
        <span style="color: green;">(请打开微信扫一扫)</span>
      </div>
      <div class="mac" v-else-if="$route.query.id == 6">
        <!-- <img class="load_jpg" :src="parent_icon" alt="" /> -->
        <div class="qrcodeDiv">
          <vue-qr
              :margin="0"
              text="https://xiaoguoai.cn/parents"
              :logoSrc="url"
              colorDark="#000000"
              colorLight="#fff"
              :correctLevel="3"
              :logoScale="0.2"
              :size="200"
          >
          </vue-qr>
        </div>
        <br>
        <span style="color: green;">家长端</span>
        <br>
        <span style="color: green;">(请打开微信扫一扫)</span>
      </div>
      <div class="mac" v-else-if="$route.query.id == 5">
        <!-- <img class="load_jpg" :src="teacher_icon" alt="" /> -->
        <div class="qrcodeDiv">
          <vue-qr
              :margin="0"
              text="https://xiaoguoai.cn/teacherM"
              :logoSrc="url"
              colorDark="#000000"
              colorLight="#fff"
              :correctLevel="3"
              :logoScale="0.2"
              :size="200"
          >
          </vue-qr>
        </div>
        <br>
        <span style="color: green;">教师端</span>
        <br>
        <span style="color: green;">(请打开微信扫一扫)</span>
      </div>
    </div>
  </div>
</template>

<script>
 
import logo from "@/assets/image/logo300.png";
export default {
  data() {
    return {
      url:logo
    };
  },

  methods: {},
};
</script>

<style lang="stylus" scoped>
.mac{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qrcodeDiv{
  width: 200px;
  height: 200px;
}

#browser
  .content
    box-sizing border-box
    padding 200px 200px
    margin-top 77px
    height 800px
    display flex
    flex-flow row nowrap
    justify-content center
    background #f7f7f7
    .mac
      margin 0 10%
      border-radius 10px
      display flex
      flex-flow column nowrap
      justify-content center
      align-items center
      font-weight 700
      color #606266
      cursor pointer
      transition all .5s ease
      .load_jpg
        width 300px
        height 300px
        margin-bottom 20px
</style>
